<template>
  <DwellingTypeFormStep :progress-value="progressValue" />
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DwellingTypeFormStep from '~/components/form/steps/dwellingType/DwellingTypeFormStep'

export default {
  name: 'DwellingTypePage',

  components: {
    DwellingTypeFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type woning',
      path: '/offertes-aanvragen/type-woning',
      progressValue: 20,
    }
  },
}
</script>
