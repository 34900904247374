<template>
  <Transition v-if="groupHelp" name="fade" mode="out-in">
    <div
      :key="groupHelp"
      class="group-help bg-primary-100 text-primary p-2 flex space-x-2 rounded-lg mt-2"
    >
      <div class="group-help__icon">
        <InlineSvg
          :src="
            require('chimera/all/assets/images/tooltip-information-sans-serif.svg')
          "
          alt="Information icon"
          class="h-4 w-4 mt-[2px] text-primary"
        />
      </div>
      <p class="group-help__body text-sm" v-html="groupHelp" />
    </div>
  </Transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'TSelectableGroupHelp',

  components: { InlineSvg },

  props: {
    choices: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },
  },

  computed: {
    /**
     * HTML formatted help text for the selected choice.
     *
     * @returns {string|undefined}
     */
    groupHelp() {
      return this.choices.find(
        (choice) => choice.value === this.value[0]?.value,
      )?.options?.groupHelp
    },
  },
}
</script>
