<template>
  <section>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <PriceRangeFormPart
        :is-optional="true"
        @result="onResult"
        @change="onChange"
      />
      <FormStepSubmitButton id="submitPriceRange" text="Volgende" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PriceRangeFormPart from 'chimera/realEstateAgency/components/form/parts/priceRange/PriceRangeFormPart'
import ContactInformationFormPage from '~/pages/offertes-aanvragen/je-gegevens'

export default {
  name: 'PriceRangeFormStep',

  components: {
    PriceRangeFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(ContactInformationFormPage)
    },
  },
}
</script>
