<template>
  <ButtonColumnFormPart
    :class="[`field-${field}`]"
    :buttons="choices"
    @click="onClick"
  />
</template>

<script>
import ButtonColumnFormPart from 'chimera/all/components/form/parts/ButtonColumnFormPart'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'dwelling-type'
export default {
  name: 'DwellingTypeField',

  components: {
    ButtonColumnFormPart,
  },

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    isRemembered: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    value: '',
  }),

  watch: {
    /**
     * @param {object} value
     */
    value(value) {
      this.validate()
    },
  },

  methods: {
    /**
     * @param button
     */
    onClick(button) {
      this.value = button.value
    },
  },
}
</script>
