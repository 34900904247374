<template>
  <AbstractFormPart :title="question">
    <DwellingTypeField :choices="choices" v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import DwellingTypeField from 'chimera/all/components/form/fields/dwellingType/DwellingTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'SalesDwellingTypeFormPart',

  components: {
    DwellingTypeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('Woonhuis', 'Woonhuis', 'Woonhuis'),
        new Selectable('Appartement', 'Appartement', 'Appartement'),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      return 'Wat voor woning wil je verkopen?'
    },
  },
}
</script>
