<template>
  <div />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export default {
  name: 'AbstractTSelectableGroupFormField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      value: [],
    }
  },

  watch: {
    /**
     * @param {Array} value
     */
    value(value) {
      this.reset()

      value = this.getOrderedValue(value)

      this.validate(value)
    },
  },

  methods: {
    /**
     * @param {Array} valueArray
     * @returns {Array}
     */
    packValue(valueArray) {
      return [...valueArray].map(({ id, value }) => {
        const { prefix = '' } = this.getTextFieldConfigForId(id)
        const beginsWithPrefix = prefix && value.startsWith(prefix)
        return {
          id,
          value: beginsWithPrefix ? value : `${prefix}${value}`.trim(),
        }
      })
    },

    /**
     * @param {Array|undefined} valueArray
     * @returns {Array}
     */
    unpackValue(valueArray = []) {
      return [...valueArray].map(({ id, value }) => {
        const { prefix = '' } = this.getTextFieldConfigForId(id)
        const beginsWithPrefix = prefix && value.startsWith(prefix)
        return {
          id,
          value: beginsWithPrefix ? value.replace(prefix, '').trim() : value,
        }
      })
    },

    /**
     * @param {string} id
     * @returns {object}
     */
    getTextFieldConfigForId(id) {
      const choiceWithTextField = this.choices.find(
        (choice) => choice.id === id,
      )
      if (!choiceWithTextField || !choiceWithTextField.options.textField) {
        return {}
      }

      return choiceWithTextField.options.textField
    },

    /**
     * Return value in order of choices
     *
     * @param {Array} value
     * @returns {Array}
     */
    getOrderedValue(value = []) {
      const orderedValues = []

      this.choices.forEach((choice) => {
        const found = value.find((val) => val.id === choice.id)
        if (found) {
          const { id, value } = found
          orderedValues.push({ id, value })
        }
      })

      return orderedValues
    },
  },
}
</script>
