<template>
  <AbstractFormPart :title="question">
    <PriceRangeField :choices="choices" v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import PriceRangeField from 'chimera/all/components/form/fields/priceRange/PriceRangeField'
import { field as dwellingTypeField } from 'chimera/all/components/form/fields/dwellingType/DwellingTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'PriceRangeFormPart',

  components: {
    PriceRangeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('€0 - €200.000', '€0 - €200.000', '€0 - €200.000'),
        new Selectable(
          '€200.000 - €300.000',
          '€200.000 - €300.000',
          '€200.000 - €300.000',
        ),
        new Selectable(
          '€300.000 - €400.000',
          '€300.000 - €400.000',
          '€300.000 - €400.000',
        ),
        new Selectable(
          '€400.000 - €500.000',
          '€400.000 - €500.000',
          '€400.000 - €500.000',
        ),
        new Selectable(
          '€500.000 - €600.000',
          '€500.000 - €600.000',
          '€500.000 - €600.000',
        ),
        new Selectable(
          '€600.000 - €750.000',
          '€600.000 - €750.000',
          '€600.000 - €750.000',
        ),
        new Selectable(
          'Meer dan €750.000',
          'Meer dan €750.000',
          'Meer dan €750.000',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      const dwellingType = this.$store.getters['lead/getData'](
        dwellingTypeField,
        'woning',
      )
      return this.$i18n.t('title', { dwellingType: dwellingType.toLowerCase() })
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat denk je dat je {dwellingType} waard is?"
  },
  "nl-BE": {
    "title": "Wat denkt u dat uw eigendom waard is?"
  }
}
</i18n>
