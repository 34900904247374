<template>
  <PriceRangeFormStep :progress-value="progressValue" />
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PriceRangeFormStep from '~/components/form/steps/priceRange/PriceRangeFormStep'

export default {
  name: 'PriceRangePage',

  components: {
    PriceRangeFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Prijsklasse woning',
      path: '/offertes-aanvragen/prijsklasse',
      progressValue: 60,
    }
  },
}
</script>
